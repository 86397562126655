@charset 'utf-8';

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WavvePADO-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/WavvePADO-Regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
// 폰트패밀리 변수
// $faRegular: 'fa-regular', sans-serif;
// $faBrands: 'fa-brands', sans-serif;
// $faSolid: 'fa-solid', sans-serif;
// $icoMoon: 'icomoon', sans-serif;
// $theMify: 'themify', sans-serif;
$preReg: 'Pretendard-Regular', sans-serif;
$jalNan: 'JalnanGothic', sans-serif;
$wavveP: 'WavvePADO-Regular', sans-serif;

// 폰트 변수
$base-font-size: 10px;
$main-textColor: #444;
$sub-textColor: #eee;

$background-images: (
  'tab_logo_01.png',
  'tab_logo_02.png',
  'tab_logo_03.png',
  'tab_logo_04.png',
  'tab_logo_05.png',
  'tab_logo_06.png',
  'tab_logo_07.png',
  'tab_logo_08.png'
);

:root {
  //색상정의
  --mainColor: #333;
  --whiteColor: #fff;
  --darkColor: #000;
  --pointColor: #2349a7;
  --actColor: #a72323;

  //폰트정리
  --fontSize: 1rem;
  --fontWeightNormal: 400;
  --fontWeightBold: 700;

  //여백
  --space10: 10px;
  --space20: 20px;
  --space30: 30px;
  --space40: 40px;
  --space50: 50px;
  --space60: 60px;
  --space70: 70px;
  --space80: 80px;
  --space90: 90px;
  --space100: 100px;
}

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'thin' {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $breakpoint == 'small' {
    @media (min-width: 390px) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'big' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $preReg;
}

section {
  scroll-margin-top: 84px; /* 헤더의 높이만큼 조정 */
}

// 회사소개 스타일
.companyContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-snap-type: y mandatory;

  &__stickyBg {
    @include respond-to('thin') {
      position: sticky;
      top: 0;
      width: 100%;
      height: 781px;
      background: url(./images/ione_intro_bg.png) no-repeat center/cover;
      background-attachment: fixed;
      scroll-snap-align: start;
      z-index: 1;
    }

    @include respond-to('big') {
      height: 90vh;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.9) 100%
      );
      pointer-events: none;
    }

    .introTitleBox {
      @include respond-to('thin') {
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%,0);
        width: 90%;
        color: #fff;
        text-align: center;
        z-index: 3;
      }
      @include respond-to('big') {
        width: 60%;
        bottom: 10%;
        transform: translate(-50%, -50%);
      }

      &-cpDesc {
        @include respond-to('thin') {
          font-size: 1.5rem;
        }
        @include respond-to('big') {
          font-size: 3.5rem;
        }
      }

      &-cpDirec {
        @include respond-to('thin') {
          position: relative;
          margin: 1.5rem 0;
          font-family: $wavveP;
          font-weight: 400;
          font-size: 1.5rem;
        }

        @include respond-to('big') {
          font-size: 2rem;
        }
        &::after {
          @include respond-to('thin') {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -0.2rem;
            transform: translateX(-50%);
            width: 70%;
            height: 0.6rem;
            background: linear-gradient(
              90deg,
              rgb(255, 158, 11) 50%,
              transparent 100%
            );
            z-index: -1;
          }
          @include respond-to('big') {
            width: 100%;
          }
        }
      }

      &-cpStrong {
        @include respond-to('thin') {
            font-size: 1rem;
            font-weight: 700;
        }

        @include respond-to('big') {
          font-size: 2.5rem;
        }
      }
    }
  }

  // 회사소개 비주얼 영역
  &__content {
    @include respond-to('thin') {
      position: relative;
      padding: 0;
      margin: 0 auto;
      height: 40%;
      max-width: 1760px;
      background: #fff;
      z-index: 2;
    }
    @include respond-to('big') {
      padding: 100px 0;
    }

    .cpContentBox {
      @include respond-to('thin') {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;     
      }

      @include respond-to('big') {
        flex-direction: unset;
        align-items: unset;
        flex-wrap: unset;
      }

      .leftContent {
        @include respond-to('thin') {
          width: 100%;
          height: 400px;   
        }

        @include respond-to('big') {
          width: 50%;
          height: auto;
        }

        .lcBg {
          @include respond-to('thin') {
            width: 100%;
            height: 90%;
            background: url(./images/ione_company_main.png) no-repeat center/cover;
          }

          @include respond-to('big') {
            width: 90%;
          }
        }
      }
      .rightContent {
        @include respond-to('thin') {
          width: 100%;   
        }

        @include respond-to('big') {
          width: 50%;
        }

        .cpWork {
          margin: 0 2rem;
          .conTitle {
            font-size: 2rem;
            display: block;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          ul {
            position: relative;

            li {
              @include respond-to('thin') {
                font-size: 1.1rem;
                border-bottom: 2px solid #c5c5c5;
                padding: 20px 0;
              }
              @include respond-to('small') {
                font-size: 1.2rem;
              }
              @include respond-to('big') {
                font-size: 1.4rem;
              }
            }
          }
        }
        p {
          margin: 1rem 2rem;
          font-size: 1.4rem;
          font-weight: 400;

          &.underLineText {
            position: relative;

            @include respond-to('thin') {
              font-size: 1rem;
            }
            @include respond-to('small') {
              font-size: 1.1rem;
            }

            @include respond-to('big') {
              font-size: 1.4rem;
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: -0.2rem;
              width: 100%;
              height: 0.6rem;
              background: linear-gradient(
                90deg,
                rgb(255, 158, 11) 50%,
                transparent 100%
              );
              z-index: -1;
            }
          }

          &.smallText {
            @include respond-to('thin') {
              font-size: 1rem;
              word-break: break-all;
              color: #515152;
            }
            @include respond-to('small') {
              font-size: 1.1rem;
            }
            @include respond-to('big') {
              font-size: 1.3rem;
            }
          }

          .emText {
            font-weight: 700;
            text-decoration: underline;
          }

          &.textRight {
            @include respond-to('thin') {
              text-align: right;
              font-weight: 500;
              font-size: 1rem;
            }
            @include respond-to('small') {
              font-size: 1.1rem;
            }
            @include respond-to('big') {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

// 아이원 결제 서비스 섹션
.payService {
  position: relative;
  height: 100%;

  @include respond-to('thin') {
    padding: 20px;
    height: 100%;         
  }
  @include respond-to('small') {
    height: 100%;
  }
  @include respond-to('tablet') {
    height: 100vh;
  }

  &__title {
    text-align: center;

    @include respond-to('thin') {
      font-size: 1.5rem;
      padding: 30px 0 20px;       
    }
    @include respond-to('small') {
      font-size: 22px;
    }
    @include respond-to('big') {
      font-size: 26px;
    }
  }

  &__serviceTitle {
    font-family: $jalNan;
    text-align: center;
    color: #444;

    @include respond-to('thin') {
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
    @include respond-to('small') {
      font-size: 24px;
    }
    @include respond-to('tablet') {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
    @include respond-to('desktop') {
    }
    @include respond-to('big') {
      font-size: 40px;
      margin: 40px 0;
    }
  }

  // 서비스소개 박스 스타일

  &__serviceBox {
    @include respond-to('thin') {
      display: flex;
      flex-wrap: wrap; 
      gap: 20px;
      justify-content: center;      
    }

    @include respond-to('desktop') {
      padding: 0 5px;
      margin-top: 30px;
      justify-content: space-around;
    }
    @include respond-to('big') {
      padding: 0 5px;
      margin-top: 100px;
      justify-content: space-around;
    }

    &-item {
      position: relative;
      transition: transform 1s ease;
      @include respond-to('thin') {
        width: 140px;
        height: 160px;
        border-radius: 20px;

        &:nth-child(3),
        &:nth-child(4) {
          margin: 20px 0 40px;
        }

        &:nth-child(4) {
          position: relative;
          img {
            opacity: 1;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 20px;
          }
          
        }

        .moveBtn {
          display: inline;
        }
      }
      @include respond-to('small') {
        width: 160px;
        height: 180px;
        border-radius: 20px;

        &:nth-child(3),
        &:nth-child(4) {
          margin: 40px 0 60px;
        }

        &:nth-child(4) {
          position: relative;
          img {
            opacity: 1;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 20px;
          }
          
        }

        .moveBtn {
          display: inline;
        }
      }

      @include respond-to('tablet') {
        width: 240px;
        height: 220px;
        &:nth-child(3),
        &:nth-child(4) {
          margin: unset;
        }
      }

      @include respond-to('desktop') {
        width: 315px;
      }

      @include respond-to('big') {
        width: 320px;
        height: 330px;

        &:hover {
          img {
            transform: scale(1.02);
            transition: transform 1s ease;
          }
          .itemOverlay {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
        }
      }

      

      img {
        display: block;
        width: inherit;
        height: inherit;
        border-radius: 20px;
        cursor: pointer;

        @include respond-to('thin') {
          width: 100%;
          height: 100%;   
        }

        @include respond-to('big') {

        }
      }

      .itemOverlay {
        cursor: pointer;
        @include respond-to('thin') {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 62px;
            background-color: #0000008f;
            border-radius: 0 0 20px 20px;
        }

        @include respond-to('big') {
          height: 132px;
        }

        p {
          @include respond-to('thin') {
            color: #fff;
            line-height: 62px;
            text-align: center;
            font-weight: 700;
          }


          @include respond-to('big') {
            font-size: 26px;
            line-height: 132px;
          }
        }
      }
    }
  }
}

// 서비스소개 어바웃페이지 terminal

.terminalPage {
  
  @include respond-to('thin') {
    position: relative;
    padding: 84px 0;
    width: 100%;
    background: #fff;
  }

  @include respond-to('big') {
    padding: 90px 0;
  }

  .tpInner {
    max-width: 100%;
    margin: 0px auto;
    .titleList {
      background: #c1d6db;
      width: 100%;
      min-height: 250px;
      margin: 0 auto;

      @include respond-to('big') {
        padding: 2rem 1rem;
      }

      .bigTitle {

        @include respond-to('thin') {
          position: relative;
          width: 100%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        @include respond-to('big') {
          width: 90%;
          flex-direction: unset;
          max-width: 1760px;
        }

        // 왼쪽 컨텐츠 영역
        .leftTitleContent {
          @include respond-to('thin') {
            width: 100%;
            padding: 1.5rem;
            margin: 0 auto;
          }
          @include respond-to('small') {
            padding: 2rem;
          }
          @include respond-to('big') {
            width: 60%;
            padding: 0 5rem;
          }


          .bigDesc {
            @include respond-to('thin') {
              display: block;
              text-align: center;
              font-size: 1.3rem;
            }
            @include respond-to('small') {
              font-size: 1.5rem;
            }
            @include respond-to('big') {
              display: inline-block;
              font-size: 2rem;
              text-align: unset;
            }
          }

          .smallDesc {
            
            @include respond-to('thin') {
              margin-top: 1rem;
              font-size: 1rem;
              text-align: center;
            }
            @include respond-to('small') {
              font-size: 1.1rem;
            }
            @include respond-to('big') {
              font-size: 1.5rem;
              display: block;
              text-align: unset;
            }
          }
        }

        // 오른쪽 컨텐츠 영역
        .rightTitleContent {
          
          @include respond-to('thin') {
            width: 25%;
            margin: 0 auto;
          }
          @include respond-to('small') {
            width: 30%;
          }
          @include respond-to('big') {
            width: 20%;
            text-align: center;
          }

          img {

            @include respond-to('thin') {
              width: 200px;
              height: auto;
            }

            @include respond-to('big') {
              width: 150px;

            }
          }
        }
      }
    }

    // 단말기 영역
    .tpConentBox {
      @include respond-to('thin') {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 2rem auto;
        padding: 0 1rem;
      }
      @include respond-to('big') {
        flex-wrap: unset;
        max-width: 1760px;
        padding: unset;      
      }

      .item {
        cursor: pointer;

        &-title {
          text-align: center;
          margin-top: 1rem;
          color: #8b0000;
        }
      }
    }

    .relatedProducts {
      width: 100%;
      max-width: 1760px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      

      .relatedProduct {
        position: relative;
        margin: 4rem 1rem;
        // width: calc(25% - 10px);
        // height: 230px;
        transition: transform .3s ease;
        transform-origin: center;
        box-sizing: border-box;
        cursor: pointer;
        
        &:hover {
          transform: scale(1.04);
        }
        

        img {
          width: 100%;
          height: 100%;
        }

        .specialImg {
          width: 280px;
          height: 400px;
          display: block;
          margin: 0 auto;
        }

        &-title {
          font-size: 1.2rem;
          text-align: center;
          margin: 1rem 0 ;
          text-transform: uppercase;
          color: #444;
        }

        &-plusPageBtn {
          display: block;
          text-align: right;
          margin-top: -2.5rem;
        }
      }
    }
  }
}


// 상품디테일 페이지

.productDetail {
  margin: 0 auto;

  @include respond-to('thin') {
    position: relative;
    padding: 84px 0;
    width: 100%;
    background: #fff;
  }
  @include respond-to('big') {
    padding: 90px 0;
  }

  .pdInner {
    
    margin: 0 auto;
    padding: 2rem 0;

    .pdTitle {
      @include respond-to('thin') {
        font-size: 1.5rem;
        text-align: center;
        margin: 1rem 0;
      }
      @include respond-to('small') {
        margin: 2rem 0;
      }
      @include respond-to('big') {
        font-size: 3rem;
      }
    }

    .type1 {
      
      @include respond-to('thin') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
      }
      @include respond-to('small') {
        padding: 2rem 0;
      }
      @include respond-to('big') {
        align-items: flex-end;
        flex-direction: unset;
      }

      .detailLeft {
        @include respond-to('thin') {
          width: 90%;
          text-align: center;
        }

        @include respond-to('big'){
          width: 20%;
          text-align: right;
        }

        p {
          
          @include respond-to('thin') {
            margin: .5rem 0;
            font-size: 1rem;
          }
          @include respond-to('big') {
            font-size: 1.5rem;
            margin: 1rem 0;
          }
        }
        
      }
      .detailRight {
        @include respond-to('thin') {
          width: 100%;
        }
        @include respond-to('big') {
          width: 35%;
        }
        img {
          @include respond-to('thin') {
            display: block;
            width: 100%;
            margin: 1rem auto 0;
          }
          @include respond-to('big') {
            margin: unset;
          }
          
        }
      }
    }
    .type2 {
      @include respond-to('thin') {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0;
        background-color: #eee;
      }
      @include respond-to('big') {
        align-items: flex-end;
        flex-direction: unset;
      }

      .detailLeft {
        @include respond-to('thin') {
          width: 100%;
        }
        @include respond-to('big') {
          width: 20%;
        }

        p {
          @include respond-to('thin') {
            font-size: 1rem;
            text-align: center;
          }
          @include respond-to('big') {
            font-size: 1.5rem;
            text-align: unset;
            // text-align: unset;
          }
        }
      }

      .detailLeft {
        @include respond-to('thin') {
          width: 100%;
          padding: 0 10px;
        }
        @include respond-to('big') {
          width: 20%;
          padding: unset;
        }

        .flexTextItem {

          font-size: 1rem;

          .flexSpanText {
            @include respond-to('thin') {
              margin: 1rem auto;
              width: 90%;
              font-size: .875rem;
              text-align: center;
            }
            @include respond-to('big') {
              text-align: unset;
              font-size: unset;
              width: unset;
            }

            span {
              margin: .5rem 0;
            }
          }
        }

        .flexConItem {
          display: flex;
          justify-content: center;
          align-items: center;

          img {

            &:nth-of-type(1) {
              @include respond-to('thin') {
                width: 100px;
                height: 100px;
                margin-top: -15px;
                object-fit: cover;
                object-position: 50% 50%;
              }

              @include respond-to('big') {
                width: 150px;
                height: 125px;
              }
            }

            &:nth-of-type(2) {
              @include respond-to('thin') {
                width: 110px;
                height: 110px;
                object-fit: cover;
                object-position: 50% 50%;
              }

              @include respond-to('big') {
                width: 170px;
                height: 135px;
              }
            }

            &:nth-of-type(3) {
              @include respond-to('thin') {
                width: 110px;
                height: 135px;
                object-fit: cover;
                object-position: 50% 50%;
              }

              @include respond-to('big') {
                width: 120px;
                height: 155px;
              }
            }

          }
        }
      }

      .detailRight.flexItem {
        
        @include respond-to('thin') {
          display: flex;
          width: 100%;
        }
        @include respond-to('big') {
          width: 35%;
        }

        img {
          @include respond-to('thin') {
            width: 49.4%;
            height: 350px;
          }
          @include respond-to('big') {
            width: 65%;
            height: unset;
          }
        }

      }
    }
    .sameAt {
      
      @include respond-to('thin') {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 0 1rem;
      }
      @include respond-to('small') {
        padding: 4rem 0 1rem;
      }

      .alignItem {
        text-align: center;
        img {

          &:nth-of-type(1) {
            width: 70%;
            object-fit: cover;
            object-position: 50% 50%;
          }

          &:nth-of-type(2) {
            margin-top: 2rem;
          }
        }
        p {
          margin-top: 2rem;
          &:nth-of-type(1) {
            @include respond-to('thin') {
              font-size: 1.1rem;
            }
            @include respond-to('small') {
              font-size: 1.2rem;
            }
            @include respond-to('big') {
              font-size: 1.5rem;
            }
          }
          &:nth-of-type(2) {
            
            @include respond-to('thin') {
              font-size: .975rem;
              font-weight: 400;
            }
            @include respond-to('big') {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
    .type4 {
      background: #eee;

      .textRight {
        

        @include respond-to('thin') {
          text-align: center;
        }
        @include respond-to('big') {
          text-align: right;
        }

        &:nth-of-type(2) {
          @include respond-to('thin') {
            margin: unset;
            padding: unset;
          }

        }
      }
    }
    .type5 {
      @include respond-to('thin') {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 0 5rem;
        height: auto;
      }

      @include respond-to('big') {
        padding: 0 0 2rem;
        height: unset;
      }


      .abItem {
        
        @include respond-to('thin') {
          position: absolute;
          right: 2%;
          bottom: 0;
        }
        @include respond-to('big') {
          right: 25%;
          bottom: -10%;
          transform: translate(-50%,-50%);
        }

        p {
          
          @include respond-to('thin') {
            font-size: .915rem;
            margin: 1rem 0;
            text-align: center;
          }

          @include respond-to('big') {
            font-size: 1.5rem;
            text-align: unset;
          }
        }

        span {
          
          @include respond-to('thin') {
            display: block;
            font-size: .875rem;
            text-align: center;
          }
          @include respond-to('big') {
            font-size: unset;
          }
        }
      }
    }
    .type6 {
      
      @include respond-to('thin') {
        display: none;
        padding: 2rem 0;
        width: 100%;
        margin: 3rem auto;
        background: #eee;
      }
      @include respond-to('big') {
        display: block;
      }
      
      img {
        
        @include respond-to('thin') {
          display: block;
          width: 100%;
          object-fit: cover;
          margin: 0 auto;
        }
        @include respond-to('big'){
          width: 70%;
          margin: 0 auto;
          transform: unset;
        }
      }
    }
    .type7 {

      max-width: 1760px;
      padding: 2rem 0;
      margin: 0 auto;

      .typeInner {
        
        @include respond-to('thin') {
          width: 90%;
          margin: 2rem auto 0;
        }
        @include respond-to('big') {
          width: 50%;
          margin: 5rem auto 0;
        }

      }

      h3 {
        
        @include respond-to('thin') {
          padding-bottom: 1rem;
          font-size: 1.3rem;
          border-bottom: 2px solid #333;
          font-weight: 400;
          color: #363636;
        }
        @include respond-to('small') {
          font-size: 1.5rem;
        }
        @include respond-to('big') {
          font-size: 3rem;
        }
      }

      dl {
        border-bottom: 1px solid #8d8d8d;
        margin: 1rem;
        padding-bottom: 1rem ;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      dt {
      
        @include respond-to('thin') {
          margin-right: 5rem;
          width: 10%;
        }

      }
      
      dl {
        text-align: left;
      }

      p {
        @include respond-to('thin') {
          text-align: center;
          font-size: .925rem;
          width: 75%;
          margin: 0 auto;
        }

        @include respond-to('big') {
          font-size: unset;
          width: unset;
          margin: unset;
          text-align: right;
        }
      }
    }
  }
}


// 포스 디테일 페이지

.productDetail.posDetail {

  padding-bottom: unset;

  .pdTitle {
    @include respond-to('thin') {
      font-size: 2rem;
    }
  }

  .type1 {

    .detailLeft {

      p {
        @include respond-to('thin') {
          font-size: 2rem;
          font-weight: 700;
          color: #444;
        }
        @include respond-to('big') {
          font-size: 2.5rem;
        }
      }

      span {
        @include respond-to('thin') {
          font-weight: 400;
          font-size: .925rem;
        }
        @include respond-to('big') {
          font-size: unset;
        }
      }
    }
  }


  .newType {

    background: #eee;
    padding: 2rem 0;
    
    @include respond-to('thin') {
      .changePc {
        display: none;
      }
      .changeMob {
        display: block;
      }
    }
    @include respond-to('big'){
      .changePc {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
      .changeMob {
        display: none;
      }
    }
  }
  .newType2 {

    padding: 2rem 0;
    &__imgBox {

      @include respond-to('thin'){
        img {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
            width: 100%;
          }
        }
      }
      @include respond-to('big'){
        img {
          &:nth-of-type(1) {
            display: block;
            width: 60%;
            margin: 0 auto;
          }
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
    }

    &__textBox {
      text-align: center;
      font-style: italic;
      p {
        @include respond-to('thin') {
          margin-top: 2rem;
          font-size: 1.5rem;
          font-weight: 400;
          color: #444;
        }
      }
      span {
        color: #444;
        font-style: italic;
        @include respond-to('thin') {
          font-size: .975rem;
          display: block;
          margin: .5rem 0;
        }
        @include respond-to('small') {
          font-size: 1rem;
          margin: .3rem 0;
        }
      }
    }
  }

  .newType3 {
  
    background: #eee;

    &__imgBox {

      @include respond-to('thin'){
        img {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
            width: 100%;
          }
        }
      }
      @include respond-to('big'){
        padding: 2rem 0;
        img {
          &:nth-of-type(1) {
            display: block;
            width: 70%;
            margin: 0 auto;
          }
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }

  .newType4 {

    padding: 2rem 0;
    @include respond-to('thin') {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    @include respond-to('big') {
      flex-direction: unset;
    }

    &__textBox {

      @include respond-to('thin') {
        width: 90%;
        text-align: center;
      }
      @include respond-to('big') {
        width: 20%;
      }
      
      p {
        font-style: italic;
        color: #444;
        @include respond-to('thin') {
          font-size: 1.3rem;
          margin-bottom: 1rem;
        }
        @include respond-to('small'){
          font-size: 1.5rem;
        }
        @include respond-to('big'){
          font-size: 2rem;
        }
      }

      span {
        
        color: #444;

        @include respond-to('thin') {
          font-size: .875rem;
        }
        @include respond-to('big') {
          font-size: .975rem;
        }
      }
    }

    &__imgBox {
      @include respond-to('thin') {
        width: 100%;
      }

      @include respond-to('big') {
        width: 35%;

      }

      img {
        width: 100%;
      }
    }
  }

  .type7.newType5 {
    padding: unset;

    .typeInner {

      @include respond-to('thin') {
        width: 100%;
      }
      @include respond-to('big'){
        padding: unset;
        margin: unset;
        width: 100%;
      }

      img {
        @include respond-to('thin') {

          &:nth-of-type(1), &:nth-of-type(2) {
            display: none;
          }
          &:nth-of-type(3), &:nth-of-type(4) {
            width: 100%;
          }
        }
        @include respond-to('big') {

          &:nth-of-type(1), &:nth-of-type(2) {
            display: block;
            width: 45%;
            margin: 0 auto;
          }
          &:nth-of-type(3), &:nth-of-type(4) {
            display: none;
            width: unset;
          }
        }
      }
      
    }
    
  }
}




// 키오스크 디테일 페이지
.kioskProduct {

  position: relative;
  padding: 90px 0;

  .kpInner {
    max-width: 1760px;
    margin: 0 auto;

    h3 {
      font-size: 1.7rem;
      color: #444;
      padding-bottom: 1rem;
      border-bottom: 1px solid #444;

      @include respond-to('thin') {
        padding: 1rem;
        margin: 1rem;
      }

      @include respond-to('big') {
        margin: 2rem 0;
      }
    }

    &__topItem {
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-to('thin') {
        flex-direction: column;
      }
      @include respond-to('big') {
        flex-direction: unset;
        margin-top: 5rem;
      }

      &-leftContent {
        width: 50%;

        @include respond-to('thin') {
          width: 100%;
        }

        @include respond-to('big') {
          width: 50%;
        }

        img {
          display: block;
          width: inherit;
          margin: 0 auto;
        }
      }
      &-rightContent {
        width: 50%;

        @include respond-to('thin') {
          width: 90%;
          margin: 1.8rem 0;
        }
        @include respond-to('small') {

          margin: 2rem 0;
        }
        @include respond-to('big') {
          width: 50%;
        }

        dl {
          display: flex;
          border: 1px solid #dadada;
          border-right: none;
          
          dt{
            width: 20%;
            background: #eee;
            padding: 1rem 0;
            text-align: center;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          dd {
            width: 80%;
            padding: 16px 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }

    &__middleItem {
      .pcItem {

        @include respond-to('thin') {
          display: none;
        }
        @include respond-to('big') {
          display: block;
          margin-top: 12rem;
          width: 100%;

          img {
            display: block;
            width: 65%;
            max-width: 100%;
            margin: 0 auto;
          }
        }

      }
      .mobItem {

        @include respond-to('thin') {
          display: block;
          width: 100%;
          margin: 0 auto;

          img {
            width: 100%;
            margin: 1.5rem 0;
          }
        }
        @include respond-to('big') {
          display: none;
        }
      }
    }
  }
}



// 오프라인 간편 결제 섹션 스타일

.tabMenuContainer {
  position: relative;
  height: 100vh;
  padding-top: 84px;

  @include respond-to('thin') {
    height: 100%;
    padding-top: unset;
  }

  @include respond-to('big') {
    height: 100vh;
    padding-top: unset;
  }

  .tabMenu {
    width: 100%;
    background: #c1d6db;

    &__tabList {
      display: flex;
      justify-content: space-around;

      @include respond-to('big') {
        justify-content: center;
      }

      &-item {
        position: relative;
        height: 80px;
        font-size: 20px;
        padding: 20px 30px;
        color: #8b9194;
        line-height: 40px;
        cursor: pointer;

        @include respond-to('thin') {
          font-size: 18px;
        }

        &.active {
          color: #4e5254;
          font-weight: 700;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            width: 100%; /* li의 너비와 동일하게 언더바를 생성 */
            background: gold;
          }
        }
      }
    }
  }

  // 탭이 표현되는 세션 스타일
  .paySection {
    display: none;
    //반응형 타이틸 스타일

    @include respond-to('thin') {
      .payService__title {
        display: none;
      }
    }
    @include respond-to('big') {
      .payService__title {
        display: block;
        padding: 30px 0;
        margin-top: 20px;
      }
    }

    &.active {
      display: block;
    }

    //탭컨테이너 스타일
    .tabContainer {
      display: flex;
      flex-wrap: wrap;
      max-width: 1760px;
      margin: -30px auto 0;

      &__box {
        flex: 1 0 calc(25% - 40px);
        height: 200px;
        border-radius: 100%;
        box-sizing: border-box;
        margin: 50px 0 30px;
        text-align: center;

        @for $i from 1 through length($background-images) {
          &:nth-of-type(#{$i}) {
            .tabContainer__box-item {
              background: #f5f5f5
                url(images/#{nth($background-images, $i)})
                no-repeat
                center /
                115px
                auto;
            }
          }
        }

        @include respond-to('tablet') {
          flex-basis: calc(40% - -10px);
        }
        @include respond-to('desktop') {
          flex: 1 0 calc(25% - 40px);
        }

        &-item {
          position: relative;
          width: 200px;
          height: inherit;
          border-radius: 100%;
          margin: 0 auto;

          @include respond-to('thin') {
            width: 140px;
            height: 140px;
          }
          @include respond-to('small') {
            width: 150px;
            height: 150px;
          }

          p {
            position: absolute;
            bottom: -50px;
            width: 100%;
            color: #000;

            font-weight: 600;

            @include respond-to('thin') {
              font-size: 12px;
            }
            @include respond-to('small') {
              font-size: 13px;
            }
            @include respond-to('big') {
              font-size: 16px;
              bottom: -60px;
            }

            span {
              margin-top: 5px;

              @include respond-to('big') {
                width: 100%;
                margin-top: unset;
                font-size: 13px;
                color: #444;
              }
            }
          }
        }
      }
    }
    .endDesc {
      margin-top: 80px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #8b0000;

      @include respond-to('thin') {
        margin: 2rem;
        font-size: 1.2rem;
      }
      @include respond-to('small') {
        margin: 40px;
        font-size: 16px;
        word-break: keep-all;
      }
      @include respond-to('big') {
        margin: 40px 0 0 0;
        font-size: 24px;
      }
    }
  }

  .paySectionNext {
    display: none;
    &.active {
      display: block;
    }
    &.active ~ .paySection {
      display: none;
    }

    @include respond-to('thin') {
      .payService__title {
        display: none;
      }
    }

    @include respond-to('tablet') {
      .payService__title {
        display: block;
      }
    }
    @include respond-to('big') {
      .payService__title {
        display: block;
        margin-top: 40px;
        padding: 30px 0;
      }
    }

    .tabContainer {
      display: flex;
      flex-wrap: wrap;
      max-width: 1760px;
      margin: 50px auto;
      @include respond-to('thin') {
        margin: 0 auto;
        justify-content: space-between;
        padding: 0 30px;
      }
      @include respond-to('big') {
        margin: 0 auto;
        justify-content: space-between;
        padding: 0 30px;
      }

      &__box {
        flex: 0 1 calc(100% / 5);
        height: 200px;
        border-radius: 100%;
        box-sizing: border-box;
        margin: 50px 0 30px;
        text-align: center;

        @include respond-to('tablet') {
          flex-basis: calc(40% - -10px);
        }
        @include respond-to('desktop') {
          flex: 0 1 calc(100% / 5);
        }

        &:nth-of-type(1) {
          .tabContainer__box-item {
            background: #f5f5f5 url(./images/tab_logo_01.png) no-repeat center /
              115px auto;
          }
        }
        &:nth-of-type(2) {
          .tabContainer__box-item {
            background: #f5f5f5 url(./images/tab_logo_02.png) no-repeat center /
              115px auto;
          }
        }
        &:nth-of-type(3) {
          .tabContainer__box-item {
            background: #f5f5f5 url(./images/tab_logo_09.png) no-repeat center /
              115px auto;
          }
        }
        &:nth-of-type(4) {
          .tabContainer__box-item {
            background: #f5f5f5 url(./images/tab_logo_04.png) no-repeat center /
              115px auto;
          }
        }
        &:nth-of-type(5) {
          .tabContainer__box-item {
            background: #f5f5f5 url(./images/tab_logo_10.png) no-repeat center /
              115px auto;
          }
        }

        &-item {
          position: relative;
          width: 200px;
          height: inherit;
          border-radius: 100%;
          margin: 0 auto;

          @include respond-to('thin') {
            width: 150px;
            height: 150px;
          }

          p {
            position: absolute;
            bottom: -50px;
            width: 100%;
            color: #000;
            font-size: 16px;
            font-weight: 600;

            @include respond-to('thin') {
              font-size: 12px;
            }
            @include respond-to('small') {
              font-size: 12px;
            }

            span {
              margin-top: 5px;

              @include respond-to('big') {
                width: 100%;
                margin-top: unset;
                font-size: 13px;
                color: #444;
              }
            }
          }
        }
      }
    }
    .endDesc {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      color: #8b0000;

      @include respond-to('thin') {
        margin: 2.5rem;
        font-size: 16px; 
      }
      @include respond-to('small') {
        margin: 40px;
        font-size: 16px;
        word-break: keep-all;
      }
      @include respond-to('big') {
        margin: 40px;
      }
    }
  }
}

// 서비스플로우 스타일
.serFlow,
.serApply {
  background: #fff;
  .serFlowVisual {
    margin: 0 auto 20px;

    @include respond-to('thin') {
      width: 100%;
      padding: 0 10px;   
    }
    .imageScale {
      display: block;

      @include respond-to('thin') {
        height: 100%;
      }

      @include respond-to('tablet') {
        width: 70%;
        height: auto;
        margin: 0 auto;
      }
      @include respond-to('big') {
        object-fit: contain;
        height: 550px;
      }
    }
  }
}

.serApply {
  background: #fff;
  padding: 20px;

  .sectionTitle {
    @include respond-to('thin') {
      display: none;   
    }
    @include respond-to('big') {
      display: block;
    }
  }
  .sectionSubTitle {
    @include respond-to('thin') {
      display: none;   
    }
    @include respond-to('big') {
      display: block;
    }
  }

  .serFlowVisual {
    .imageScale {
      width: 65%;
      height: auto;

      @include respond-to('thin') {
        width: 100%;
      }
      @include respond-to('tablet') {
        width: unset;
      }
    }
  }
}

// 고객지원 섹션 스타일
.csSection {
  position: relative;
  background: #c1d6db;
  height: 100vh;

  @include respond-to('thin') {
    height: 100%;
    padding: 10px;
  }
  @include respond-to('big') {
    height: 100%;
    padding: 40px 0;
  }

  .sectionTitle {
    color: #333;
  }

  &__contentBox {
    margin: 80px 0;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include respond-to('thin') {
      margin: 40px 0;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
    @include respond-to('small') {
      margin: 60px 0;
    }

    &-conItem {
      position: relative;
      background: pink;

      box-sizing: border-box;
      background: #e9e9e9;
      padding: 10px;
      text-align: center;

      @include respond-to('thin') {
        width: 50%;
        height: 100%; 
      }
      @include respond-to('small') {
        width: 160px;
        height: 140px;
      }
      @include respond-to('tablet') {
        width: 230px;
        height: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      @include respond-to('big') {
        width: 300px;
        height: 280px;
        margin: 0 auto;
      }

      img {
        margin: 10px 0 0;

        @include respond-to('thin') {
          width: 50px;
          height: 50px;
        }

        @include respond-to('tablet') {
          width: 55px;
          height: 55px;
        }

        @include respond-to('big') {
          width: 55px;
          height: 55px;
        }
        z-index: 2;
      }

      .subMitBtn {
        display: block;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 600;
        z-index: 2;
        cursor: pointer;

        .smallText {
          font-size: 16px;

          @include respond-to('thin') {
            font-size: 12px;
            padding-top: 5px;
            color: #515152;
          }

          @include respond-to('tablet') {
            display: block;
          }

          @include respond-to('big') {
            font-size: 16px;
            font-weight: 500;
          }
        }

        @include respond-to('small') {
          font-size: 14px;
        }
        @include respond-to('big') {
          padding-top: unset;
          font-size: 22px;
          font-weight: 600;
          display: block;
          width: 100%;
          padding: 40px;
          color: #444;
          transition: color 0.4s ease;
        }

        &:hover {
          color: #000;
        }
      }

      .csBorder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        background: transparent;
        border: 2px solid #fff;
        cursor: pointer;
      }
    }
  }

  .endSectionTitle {
    @include respond-to('thin') {
      margin-top: 20px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 700;
      word-break: keep-all;
      text-align: center;
      color: #4e5254;
    }
    @include respond-to('tablet') {
      text-align: center;
      width: 100%;
    }
    @include respond-to('big') {
      display: block;
      font-size: 20px;
      text-align: center;
    }
  }
}



// 문의작성 폼페이지
.inquiryModal {
  position: relative;
  padding: 90px 0 0;
  &__content {
    @include respond-to('thin') {
      height: 100%;
      background: #eee;
    }
    @include respond-to('big') {
      max-width: 1600px;
      margin: 2rem auto;
      padding: 4rem;
    }

    &-title {
      
      border-bottom: 2px solid #c7c7c7;
      color: #444;

      @include respond-to('small') {
        font-size: 1.5rem;
        padding: 1rem 0 1rem .5rem;
      }
      @include respond-to('big') {
        padding: 0 0 1rem 0;
        margin-bottom: 2rem;
        font-size: 2rem;
      }
    }

    .iqModalForm {

      display: flex;

      @include respond-to('small') {
        flex-direction: column;
      }
      @include respond-to('big') {
        flex-direction: unset;
      }

      .leftFormContent {
        

        @include respond-to('small') {
          width: 100%;
        }
        @include respond-to('big') {
          width: 50%;
        }

      //문의페이지 FAQ 리스트
        .boardContainer {
          position: relative;

          &__box {
            &__list {
              

              @include respond-to('small') {
                display: flex;
                flex-wrap: wrap;
              }
              @include respond-to('big') {
                flex-wrap: unset;
                justify-content: flex-start;
                align-items: center;
                background: #ddd;
                border-bottom: 1px solid #333;
              }

              li {
                
                
                @include respond-to('small') {
                  padding: .5rem;
                }
                @include respond-to('big') {
                  padding: 1rem;
                  border-right: 2px solid #999999;
                }

                &:first-child {
                  border-left: none;
                }
                &:last-child {
                  border-right: none;
                }

                a {
                  

                  @include respond-to('big') {
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: #444;
                  }
                }
              }
            }
          }

          &__faq {
            height: 100%;

            @include respond-to('small') {
              margin: 2rem .5rem;
            }
            @include respond-to('big') {
              margin: unset;
            }
            dl {
              dt {
                color: #333;
                background: #fff;
                padding: 1rem 0.5rem ;
                border-bottom: 1px solid #444;
              }
              dd {
                .faq-pre {
                  padding: 1rem .5rem ;
                  white-space: pre-wrap; /* 줄 바꿈을 허용하여 좌우 스크롤 방지 */
                  word-wrap: break-word; /* 긴 단어가 있을 경우 줄 바꿈 */
                  overflow: hidden; /* 스크롤 방지 */
                  max-height: 400px; /* 필요시 최대 높이 설정 */
                  border: 1px solid #444;
                  border-left: unset;
                  border-right: unset;
                  color: #fff;
                  font-size: 1rem;
                  background: #8f8f8f;
                }
              }
            }
          }
        }
      }

      .rightFormContent {
        

        @include respond-to('small') {
          width: 100%;
          padding: 1rem;
        }
        @include respond-to('big') {
          width: 50%;
          padding: 3rem 8rem;
        }
        
        h3 {
          

          @include respond-to('small') {
            font-size: 1.5rem;
            color: #444;
            margin-bottom: 1rem;

          }
          @include respond-to('big') {
            font-size: 2rem;

          }
        }

        span {
          margin: 1rem 0;
          color: #777;
        }
        
        dl {
          dt {
            

            @include respond-to('small') {
              display: inline-block;
              width: 25%;
              margin: .3rem 0;
            }
            @include respond-to('big') {
              
              width: 20%;
              margin: .5rem 0;
              font-size: 1rem;
              font-weight: 700;
              color: #444;
            }
          }
          dd {
            display: inline;
            margin-left: 1rem;
            font-size: 1.1rem;
            font-weight: 400;
            color: #777;
          }
        }
      }
    }

    .contactUs {
      margin-top: 5rem;

      h3 {
        border-bottom: 2px solid #c7c7c7;
        color: #444;

        @include respond-to('small') {
          font-size: 1.5rem;
          padding: 1rem 0 1rem .5rem;
        }
        @include respond-to('big') {
          padding: 0 0 1rem 0;
          margin-bottom: 2rem;
          font-size: 2rem;
        }
      }

      &__formContainer {
        width: 100%;
        

        @include respond-to('small') {
          padding: 0 1rem;
        }
        @include respond-to('big') {
          padding: 0 10rem;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;


          tr {
            @include respond-to('small') {
              display: block;
            }
            @include respond-to('big') {
              display: unset;
            }
          }

          td {
            vertical-align: middle;
            
            @include respond-to('small') {
              display: block;
            }
            @include respond-to('big') {}
            
            &.numInput {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;

              .numArea {
              
                @include respond-to('small') {}
                @include respond-to('big') {
                  width: calc(33% - 20px);
                }
              }

              span {
                margin: 0 .6rem;
              }
            }

            label {
              
              @include respond-to('small') {
                width: 10%;
              }
              @include respond-to('big') {
                display: block;
                font-weight: bold;
                color: #555;
                margin-bottom: 5px;
              }
            }

            input[type="text"] {
              width: calc(33% - 20px);
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 1rem;
              background: #fff;

              &.mobInput {

                @include respond-to('small') {
                  width: 45%;
                }
                @include respond-to('big') {
                  width: 31.3%;
                }
              }

              &.nameInput {
                @include respond-to('small') {
                  width: 97%;
                }
                @include respond-to('big') {
                  width: 31.3%;
                }
              }
            }

            select {
              
              margin-left: 5px;
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 1rem;
              background: #fff;

              @include respond-to('small') {
                width: 45%;
              }
              @include respond-to('big') {
                width: 31%;
              }
            }
          }

          tr:not(:last-child) td {
            padding-bottom: 15px;
          }
          .textAreas {

            width: 100%;

            label {
              width: 100%;
              display: block;
              margin-bottom: 1.5rem;
            }
            textarea {
              width: 97.5%;
              resize: none;
              border: 1px solid #ccc;

              @include respond-to('big') {
                width: 65%;
              }
            }
          }
        }
      }

      // 개인정보동의 스타일
      .agreeApply {
        margin-bottom: 20px;
        width: 100%;
        padding: 2rem 1rem;
        background: rgb(255, 255, 255);
  
        span {
          font-size: 1rem;
          color: #666;
          line-height: 1.5;
          display: block;
          margin-bottom: 10px;
        }
  
        .dropBox {
          font-size: 0.9rem;
          color: #666;
          line-height: 1.5;
          margin: 2rem 0;

          span {
            display: block;
          }
        }
  
        input[type="checkbox"] {
          margin-right: 10px;
          transform: scale(1.2);
        }

        #agreeText {
          display: inline;
        }
      }
      
      .iqModalForm__submit {
        
        display: block;
        background-color: #8a8a8a;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        @include respond-to('small') {
          padding: 1rem 3rem;
          margin: 1rem auto;
          font-size: 1rem;
        }
        @include respond-to('big') {
          padding: 1rem 4rem;
          margin: 0 auto;
          font-size: 1.125rem;
        }
    
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}



// @media only screen and (max-width: 429px) {
//   // 목업핸들
//   .imgHandle {
//     position: absolute;
//     bottom: 0;
//     left: -20%;
//     width: 400px;
//     height: 250px;
//     // z-index: 4;
//   }
//   // 메인비주얼 타이틀
//   .mainVisualTitle {
//     font-size: 0.875rem !important;
//     // white-space: nowrap;
//   }
//   .subVisualTitle {
//     font-size: 1.3rem !important;
//     font-weight: 700;
//   }

//   .intStrongText {
//     font-size: 1.6rem;
//     margin-bottom: 1rem;
//   }

//   // 서비스소개 아이템
//   .serviceTitle {
//     padding: 1.25rem;
//     font-size: 1.7rem;
//     font-weight: 700;
//     text-align: center;
//     color: #2349a7;
//   }

//   // 서비스의 흐름
//   .serviceFlow {
//     margin-top: 2rem;
//     width: 100%;
//     height: 1200px;

//     .serviceFlowImg {
//       background: url(./images/serviceImgMob.jpg) no-repeat center/cover;
//       display: block;
//       width: 100%;
//       height: 100%;
//     }
//   }

//   .colServiceSection {
//     text-align: center;
//     margin: 4rem auto;
//     width: 65vw;
//     height: 60vh;

//     h2 {
//       text-align: center;
//       margin-bottom: 2rem;
//     }
//     &__imgBox {
//       display: block;
//       max-width: 85%;
//       height: 85%;
//       margin: 0 auto;
//       &__img {
//         background: url('./images/serviceFlowMob.png') no-repeat center / cover;
//         display: block;
//         max-width: 100%;
//         height: inherit;
//         margin: 0 auto;
//       }
//     }
//   }

//   .section6 {
//     display: none;
//   }

//   // midle_content 영역
//   .changeIcon-1 {
//     background: url('/images/img22.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-2 {
//     background: url('/images/img24.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-3 {
//     background: url('/images/img25.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;

//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }
//   .changeScaleIcon {
//     transition:
//       transform 0.5s ease,
//       opacity 0.5s ease;
//     width: 250px;
//     min-width: 250px;
//     max-width: 300px;
//     &:hover {
//       transform: scale(1.1);
//       opacity: 1;
//     }
//   }

//   // 회사소개 페이지 모바일 퍼스트 CSS
//   .companyContainer {
//     background: url(./images/visualBg.jpg) no-repeat center/cover;
//     padding-top: 120px;
//     height: 100%;
//     overflow: hidden;
//     opacity: 0.9;

//     &::before {
//       content: '';
//       display: block;
//       position: absolute;
//       top: 15%;
//       left: 45%;
//       transform: translateX(-50%);
//       width: 100px;
//       height: 100px;
//       background: rgba(255, 217, 0, 0.322);
//       box-shadow: 0px 0px 100px gold;
//       border-radius: 100%;
//     }

//     &__inner {
//       max-width: 1600px;
//       margin: 0 auto;
//       background: rgba(107, 107, 107, 0.219);

//       &__content {
//         position: relative;
//         &__title {
//           text-align: center;
//           font-weight: 700;
//           font-size: 1.5rem;
//           color: #eee;
//         }
//         &__desc {
//           width: 100%;
//           margin: 2rem auto;
//           padding: 1rem 4rem;
//           text-align: left;
//           font-weight: 700;
//           font-size: 1.5rem;
//           font-style: italic;
//           color: #fff;
//           border-top: 2px solid #fff;
//           border-bottom: 2px solid #fff;

//           span {
//             display: block;
//             margin-top: 10px;
//             text-align: right;
//             font-size: 1.5rem;
//             font-style: italic;
//           }
//         }

//         &__descText {
//           width: 70%;
//           margin: 2rem auto;
//           text-align: center;
//           font-size: 0.925rem;
//           font-weight: 700;
//           word-break: keep-all;
//           color: #fff;
//         }

//         &__textImportant {
//           width: 75%;
//           margin: 2rem auto;

//           p {
//             font-size: 0.925rem;
//             text-align: center;
//             color: #fff;
//             font-weight: 400;

//             &.importantStrong {
//               font-weight: 700;
//               font-size: 1.6rem;
//             }
//           }

//           .importantSpan {
//             margin: 1rem 0;
//             word-break: break-all;
//             text-align: center;
//             color: #fff;
//             font-weight: 700;
//             font-size: 0.975rem;
//           }
//         }

//         &__importantDesc {
//           margin: 2rem auto;
//           p {
//             width: 100%;
//             padding: 0 1rem;
//             margin: 0 auto;
//             line-height: 2;
//             color: #fff;
//             font-size: 0.925rem;
//             word-break: break-all;

//             .t-bold {
//               color: gold;
//               font-size: 1rem;
//               font-weight: 700;
//             }
//           }
//           .ceoName {
//             width: 70%;
//             margin: 0 auto;
//             padding-top: 2rem;
//             color: #fff;
//             font-size: 1.1rem;
//             font-weight: 600;
//             text-align: right;

//             span {
//               display: block;
//               margin-top: 10px;
//               padding-bottom: 2rem;
//               font-weight: 700;
//               text-align: right;
//             }
//           }
//         }
//       }
//     }
//   }

//   #kakaoLiveContent {
//     text-align: center;
//     margin: 0 auto;
//   }

//   .kakaoLiveContent {
//     p {
//       text-align: center;
//     }
//   }
//   #kakaoTitle {
//     text-align: center;
//     color: #222;
//   }
//   #kakaoLiveBtn {
//     width: 40%;
//     margin-bottom: 10px;
//     padding: 10px;
//     font-weight: 700;
//     background: #f9e000;
//     color: #222;
//   }

//   .galleryPage {
//     position: relative;
//     padding: 0 30px;

//     h1 {
//       margin: 2rem 0;
//       font-size: 1.7rem;
//       font-weight: 700;
//       text-align: center;
//       color: #2349a7;
//     }

//     &__gContent {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 10px;

//       &__gImg {
//         position: relative;
//         flex-grow: 1;
//         flex-shrink: 1;
//         flex-basis: calc(60% - 15px);
//         height: 125px;
//         background-color: #ccc;
//         box-sizing: border-box;
//         overflow: hidden;

//         &:nth-child(1) {
//           background: url(./images/changeBg_1.png) no-repeat center/cover;
//         }
//         &:nth-child(2) {
//           background: url(./images/changeBg_2.png) no-repeat center/cover;
//         }
//         &:nth-child(3) {
//           background: url(./images/changeBg_3.png) no-repeat center/cover;
//         }
//         &:nth-child(4) {
//           background: url(./images/changeBg_4.png) no-repeat center/cover;
//         }
//         &:nth-child(5) {
//           background: url(./images/changeBg_5.png) no-repeat center/cover;
//         }
//         &:nth-child(6) {
//           background: url(./images/changeBg_6.png) no-repeat center/cover;
//         }

//         .overlay {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.6);
//           color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           opacity: 0;
//           transition: opacity 0.3s ease-in-out;
//           font-size: 1.2rem;
//           font-weight: 700;
//         }

//         &:hover .overlay {
//           opacity: 1;
//         }
//       }
//     }
//   }
//   
// }

// 미디어 430~ 767 px
// @media only screen and (min-width: 430px) and (max-width: 767px) {
//   // 목업핸들
//   .imgHandle {
//     position: absolute;
//     bottom: 0;
//     left: -20%;
//     width: 700px;
//     height: auto;
//     opacity: 0.9;
//     // z-index: 4;
//   }
//   // 메인비주얼 타이틀
//   .mainVisualTitle {
//     font-size: 0.875rem !important;
//     // white-space: nowrap;
//   }
//   .subVisualTitle {
//     font-size: 1.3rem !important;
//     font-weight: 700;
//   }

//   // 서비스소개 아이템
//   .serviceTitle {
//     padding: 1.25rem;
//     font-size: 1.75rem;
//     color: #333;
//   }

//   // 서비스의 흐름
//   .serviceFlow {
//     margin-top: 2rem;
//     width: 100%;
//     height: 1400px;

//     .serviceFlowImg {
//       background: url(./images/serviceImgMob.jpg) no-repeat center/cover;
//       display: block;
//       width: 75%;
//       height: 100%;
//       margin: 0 auto;
//       min-width: 430px;
//       max-width: 767px;
//     }
//   }

//   .colServiceSection {
//     text-align: center;
//     margin: 4rem auto;
//     width: 100vw;
//     height: 100vh;

//     h2 {
//       text-align: center;
//       margin-bottom: 2rem;
//     }
//     &__imgBox {
//       display: block;
//       max-width: 100%;
//       height: 100%;
//       &__img {
//         background: url('./images/serviceFlowMob.png') no-repeat center / cover;
//         display: block;
//         max-width: 70%;
//         height: inherit;
//         margin: 0 auto;
//       }
//     }
//   }

//   .section6 {
//     display: none;
//   }

//   // midle_content 영역
//   .changeIcon-1 {
//     background: url('/images/img22.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-2 {
//     background: url('/images/img24.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-3 {
//     background: url('/images/img25.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;

//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }
//   .changeScaleIcon {
//     transition:
//       transform 0.5s ease,
//       opacity 0.5s ease;
//     &:hover {
//       transform: scale(1.1);
//       opacity: 1;
//     }
//   }

//   // 회사소개 페이지 모바일 퍼스트 CSS
//   .companyContainer {
//     background: url(./images/visualBg.jpg) no-repeat center/cover;
//     padding-top: 120px;
//     height: 100%;
//     overflow: hidden;
//     opacity: 0.9;

//     &::before {
//       content: '';
//       display: block;
//       position: absolute;
//       top: 15%;
//       left: 45%;
//       transform: translateX(-50%);
//       width: 100px;
//       height: 100px;
//       background: rgba(255, 217, 0, 0.322);
//       box-shadow: 0px 0px 100px gold;
//       border-radius: 100%;
//     }

//     &__inner {
//       max-width: 1600px;
//       margin: 0 auto;
//       background: rgba(107, 107, 107, 0.219);

//       &__content {
//         position: relative;
//         &__title {
//           text-align: center;
//           font-weight: 700;
//           font-size: 1.5rem;
//           color: #eee;
//         }
//         &__desc {
//           width: 400px;
//           margin: 2rem auto;
//           padding: 1rem 4rem;
//           text-align: left;
//           font-weight: 700;
//           font-size: 1.5rem;
//           font-style: italic;
//           color: #fff;
//           border-top: 2px solid #fff;
//           border-bottom: 2px solid #fff;

//           span {
//             display: block;
//             margin-top: 10px;
//             text-align: right;
//             font-size: 1.5rem;
//             font-style: italic;
//           }
//         }

//         &__descText {
//           width: 70%;
//           margin: 2rem auto;
//           text-align: center;
//           font-size: 0.925rem;
//           font-weight: 700;
//           word-break: keep-all;
//           color: #fff;
//         }

//         &__textImportant {
//           width: 75%;
//           margin: 2rem auto;

//           p {
//             font-size: 0.925rem;
//             text-align: center;
//             color: #fff;
//             font-weight: 400;

//             &.importantStrong {
//               font-weight: 700;
//               font-size: 1.6rem;
//             }
//           }

//           .importantSpan {
//             margin: 1rem 0;
//             word-break: break-all;
//             text-align: center;
//             color: #fff;
//             font-weight: 700;
//             font-size: 0.975rem;
//           }
//         }

//         &__importantDesc {
//           margin: 2rem auto;
//           p {
//             width: 100%;
//             padding: 0 1rem;
//             margin: 0 auto;
//             line-height: 2;
//             color: #fff;
//             font-size: 0.925rem;
//             word-break: break-all;

//             .t-bold {
//               color: gold;
//               font-size: 1rem;
//               font-weight: 700;
//             }
//           }
//           .ceoName {
//             width: 70%;
//             margin: 0 auto;
//             padding-top: 2rem;
//             color: #fff;
//             font-size: 1.1rem;
//             font-weight: 600;
//             text-align: right;

//             span {
//               display: block;
//               margin-top: 10px;
//               padding-bottom: 2rem;
//               font-weight: 700;
//               text-align: right;
//             }
//           }
//         }
//       }
//     }
//   }
//   #kakaoLiveContent {
//     text-align: center;
//     margin: 0 auto;
//   }

//   .kakaoLiveContent {
//     p {
//       text-align: center;
//     }
//   }
//   #kakaoTitle {
//     text-align: center;
//     color: #222;
//   }
//   #kakaoLiveBtn {
//     width: 40%;
//     margin-bottom: 10px;
//     padding: 10px;
//     font-weight: 700;
//     background: #f9e000;
//     color: #222;
//   }
//   .galleryPage {
//     position: relative;
//     padding: 100px;

//     h1 {
//       margin: 4rem;
//       font-size: 3rem;
//       font-weight: 700;
//       text-align: center;
//       color: #2349a7;
//     }

//     &__gContent {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 10px;

//       &__gImg {
//         position: relative;
//         flex: 1 1 calc(50% - 15px); /* 2개씩 나누고 간격 고려한 너비 설정 */
//         height: auto; /* 자동 높이 조정으로 자연스러운 비율 유지 */
//         background-color: #ccc; /* 배경 색 예시 */
//         box-sizing: border-box; /* padding, border를 포함한 전체 너비 계산 */
//         overflow: hidden; /* 오버플로우되는 요소 숨기기 */

//         &:nth-child(1) {
//           background: url(./images/changeBg_1.png) no-repeat center/cover;
//         }
//         &:nth-child(2) {
//           background: url(./images/changeBg_2.png) no-repeat center/cover;
//         }
//         &:nth-child(3) {
//           background: url(./images/changeBg_3.png) no-repeat center/cover;
//         }
//         &:nth-child(4) {
//           background: url(./images/changeBg_4.png) no-repeat center/cover;
//         }
//         &:nth-child(5) {
//           background: url(./images/changeBg_5.png) no-repeat center/cover;
//         }
//         &:nth-child(6) {
//           background: url(./images/changeBg_6.png) no-repeat center/cover;
//         }

//         .overlay {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.6);
//           color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           opacity: 0;
//           transition: opacity 0.3s ease-in-out;
//           font-size: 1.2rem;
//           font-weight: 700;
//         }

//         &:hover .overlay {
//           opacity: 1;
//         }
//       }
//     }
//   }
//   // 문의페이지 FAQ 리스트
//   .boardContainer {
//     position: relative;

//     &__box {
//       &__list {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background: #ddd;
//         // border-top: 1px solid #333;
//         border-bottom: 1px solid #333;

//         li {
//           padding: 10px;
//           border-right: 2px solid #999999;

//           &:first-child {
//             border-left: none;
//           }
//           &:last-child {
//             border-right: none;
//           }

//           a {
//             font-size: 1rem;
//             font-weight: 700;
//             color: #444;
//           }
//         }
//       }
//     }

//     &__faq {
//       dl {
//         background: #eee;
//         dt {
//           color: #333;
//         }
//         dd {
//           .faq-pre {
//             white-space: pre-wrap; /* 줄 바꿈을 허용하여 좌우 스크롤 방지 */
//             word-wrap: break-word; /* 긴 단어가 있을 경우 줄 바꿈 */
//             overflow: hidden; /* 스크롤 방지 */
//             max-height: 400px; /* 필요시 최대 높이 설정 */
//             background: #d8d8d8;
//             color: #363636;
//             font-size: 1rem;
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 768px) and (max-width: 1023px) {
//   // 목업핸들
//   .imgHandle {
//     position: absolute;
//     bottom: 0;
//     left: -20%;
//     width: 700px;
//     height: auto;
//     opacity: 0.9;
//     // z-index: 4;
//   }
//   // 메인비주얼 타이틀
//   .mainVisualTitle {
//     font-size: 1.6rem !important;
//     // white-space: nowrap;
//   }
//   .subVisualTitle {
//     font-size: 2rem !important;
//     font-weight: 700;
//   }

//   // 서비스소개 아이템
//   .serviceTitle {
//     padding: 1.25rem;
//     font-size: 1.75rem;
//     color: #333;
//   }

//   // 서비스의 흐름
//   .serviceFlow {
//     margin-top: 2rem;
//     width: 100%;
//     height: 1400px;

//     .serviceFlowImg {
//       background: url(./images/serviceImgMob.jpg) no-repeat center/cover;
//       display: block;
//       width: 66%;
//       height: 100%;
//       margin: 0 auto;
//     }
//   }

//   .ser_content .ser ul .ser_con {
//     padding-left: unset;
//   }
//   .ser_content .ser ul .ser_con span {
//     padding-left: unset;
//     width: 70%;
//   }

//   .colServiceSection {
//     text-align: center;
//     margin: 4rem auto;
//     width: 90vw;
//     height: 69vh;

//     h2 {
//       text-align: center;
//     }
//     &__imgBox {
//       display: block;
//       max-width: 100%;
//       height: 80%;
//       &__img {
//         background: url(/_next/static/media/serviceFlow.0956b841.png) no-repeat
//           center / cover;
//         display: block;
//         max-width: 100%;
//         height: inherit;
//         margin: 0 auto;
//       }
//     }
//   }

//   .section6 {
//     display: none;
//   }

//   // midle_content 영역
//   .changeIcon-1 {
//     background: url('/images/img22.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-2 {
//     background: url('/images/img24.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-3 {
//     background: url('/images/img25.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;

//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }
//   .changeScaleIcon {
//     transition:
//       transform 0.5s ease,
//       opacity 0.5s ease;
//     &:hover {
//       transform: scale(1.1);
//       opacity: 1;
//     }
//   }

//   #contentWidth {
//     max-width: unset;
//   }
//   #flexItems {
//     flex-direction: column;
//     align-content: center;

//     .flexColItem {
//       max-width: unset;
//     }
//   }

//   .companyContainer {
//     background: url(./images/visualBg.jpg) no-repeat center/cover;
//     padding-top: 120px;
//     height: 100%;
//     overflow: hidden;
//     opacity: 0.9;

//     &::before {
//       content: '';
//       display: block;
//       position: absolute;
//       top: 15%;
//       left: 45%;
//       transform: translateX(-50%);
//       width: 100px;
//       height: 100px;
//       background: rgba(255, 217, 0, 0.322);
//       box-shadow: 0px 0px 100px gold;
//       border-radius: 100%;
//     }

//     &__inner {
//       max-width: 1600px;
//       margin: 0 auto;
//       background: rgba(107, 107, 107, 0.219);

//       &__content {
//         position: relative;
//         border: 2px solid #fff;
//         border-radius: 20px;
//         padding: 2rem 0;
//         margin: 2rem;
//         &__title {
//           text-align: center;
//           font-weight: 700;
//           font-size: 1.5rem;
//           color: #eee;
//         }
//         &__desc {
//           width: 550px;
//           margin: 2rem auto;
//           padding: 1rem 4rem;
//           text-align: left;
//           font-weight: 700;
//           font-size: 1.5rem;
//           font-style: italic;
//           color: #fff;
//           border-top: 2px solid #fff;
//           border-bottom: 2px solid #fff;

//           span {
//             display: block;
//             margin-top: 10px;
//             text-align: right;
//             font-size: 1.5rem;
//             font-style: italic;
//           }
//         }

//         &__descText {
//           width: 70%;
//           margin: 2rem auto;
//           text-align: center;
//           font-size: 0.925rem;
//           font-weight: 700;
//           word-break: keep-all;
//           color: #fff;
//         }

//         &__textImportant {
//           width: 75%;
//           margin: 2rem auto;

//           p {
//             font-size: 0.925rem;
//             text-align: center;
//             color: #fff;
//             font-weight: 400;

//             &.importantStrong {
//               font-weight: 700;
//               font-size: 1.6rem;
//             }
//           }

//           .importantSpan {
//             display: block;
//             margin: 1rem auto;
//             padding: 10px;
//             width: 100%;
//             font-weight: 700;
//             font-size: 0.975rem;
//             word-break: break-all;
//             background: #fff;
//             color: #111;
//           }
//         }

//         &__importantDesc {
//           margin: 2rem auto;
//           p {
//             width: 80%;
//             padding: 0 1rem;
//             margin: 0 auto;
//             line-height: 2;
//             color: #fff;
//             font-size: 0.925rem;
//             word-break: break-all;

//             .t-bold {
//               color: gold;
//               font-size: 1rem;
//               font-weight: 700;
//             }
//           }
//           .ceoName {
//             width: 80%;
//             margin: 0 auto;
//             padding-top: 2rem;
//             color: #fff;
//             font-size: 1.1rem;
//             font-weight: 600;
//             text-align: right;

//             span {
//               display: block;
//               margin-top: 10px;
//               padding-bottom: 2rem;
//               font-weight: 700;
//               text-align: right;
//             }
//           }
//         }
//       }
//     }
//   }

//   #kakaoLiveContent {
//     text-align: center;
//     margin: 0 auto;
//   }

//   .kakaoLiveContent {
//     p {
//       text-align: center;
//     }
//   }
//   #kakaoTitle {
//     text-align: center;
//     color: #222;
//   }
//   #kakaoLiveBtn {
//     width: 40%;
//     margin-bottom: 10px;
//     padding: 10px;
//     font-weight: 700;
//     background: #f9e000;
//     color: #222;
//   }

//   .galleryPage {
//     position: relative;
//     padding: 100px;

//     h1 {
//       margin: 4rem;
//       font-size: 3rem;
//       font-weight: 700;
//       text-align: center;
//       color: #2349a7;
//     }

//     &__gContent {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 10px;

//       &__gImg {
//         position: relative;
//         flex: 1 1 calc(33.333% - 20px); /* 3개씩 나누고 간격 고려한 너비 설정 */
//         height: 200px; /* 높이 예시 */
//         background-color: #ccc; /* 배경 색 예시 */
//         box-sizing: border-box; /* padding, border를 포함한 전체 너비 계산 */
//         overflow: hidden; /* 오버플로우되는 요소 숨기기 */

//         &:nth-child(1) {
//           background: url(./images/changeBg_1.png) no-repeat center/cover;
//         }
//         &:nth-child(2) {
//           background: url(./images/changeBg_2.png) no-repeat center/cover;
//         }
//         &:nth-child(3) {
//           background: url(./images/changeBg_3.png) no-repeat center/cover;
//         }
//         &:nth-child(4) {
//           background: url(./images/changeBg_4.png) no-repeat center/cover;
//         }
//         &:nth-child(5) {
//           background: url(./images/changeBg_5.png) no-repeat center/cover;
//         }
//         &:nth-child(6) {
//           background: url(./images/changeBg_6.png) no-repeat center/cover;
//         }

//         .overlay {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.6);
//           color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           opacity: 0;
//           transition: opacity 0.3s ease-in-out;
//           font-size: 1.2rem;
//           font-weight: 700;
//         }

//         &:hover .overlay {
//           opacity: 1;
//         }
//       }
//     }
//   }

//   // 문의페이지 FAQ 리스트
//   .boardContainer {
//     position: relative;

//     &__box {
//       &__list {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background: #ddd;
//         // border-top: 1px solid #333;
//         border-bottom: 1px solid #333;

//         li {
//           padding: 10px;
//           border-right: 2px solid #999999;

//           &:first-child {
//             border-left: none;
//           }
//           &:last-child {
//             border-right: none;
//           }

//           a {
//             font-size: 1rem;
//             font-weight: 700;
//             color: #444;
//           }
//         }
//       }
//     }

//     &__faq {
//       dl {
//         background: #eee;
//         dt {
//           color: #333;
//         }
//         dd {
//           .faq-pre {
//             white-space: pre-wrap; /* 줄 바꿈을 허용하여 좌우 스크롤 방지 */
//             word-wrap: break-word; /* 긴 단어가 있을 경우 줄 바꿈 */
//             overflow: hidden; /* 스크롤 방지 */
//             max-height: 400px; /* 필요시 최대 높이 설정 */
//             background: #d8d8d8;
//             color: #363636;
//             font-size: 1rem;
//           }
//         }
//       }
//     }
//   }
// }
// @media (min-width: 1024px) {
//   .imgHandle {
//     position: absolute;
//     bottom: 0;
//     left: -10%;
//     width: 800px;
//     height: auto;
//     // z-index: 4;
//   }
//   // 메인비주얼 타이틀
//   .mainVisualTitle {
//     font-size: 2.2rem;
//     font-weight: 500;
//     // white-space: nowrap;
//   }
//   .subVisualTitle {
//     font-size: 3rem;
//     font-weight: 700;
//   }

//   // 서비스소개 아이템
//   .serviceTitle {
//     margin: 4rem;
//     font-size: 3rem;
//     font-weight: 700;
//     text-align: center;
//     color: #2349a7;
//   }

//   .serviceFlow {
//     margin-top: 2rem;
//     background: url(./images/serviceImg.jpg) no-repeat center/cover;
//     display: block;
//     width: 100%;
//     height: 800px;
//   }

//   .intBigText {
//     width: 70%;
//     margin-top: unset;
//   }

//   #contentWidth {
//     max-width: unset;
//     margin-bottom: 2rem;
//   }
//   .colServiceSection {
//     text-align: center;
//     margin: 4rem auto;
//     width: 88vw;
//     height: 66vh;

//     h2 {
//       text-align: center;
//     }
//     &__imgBox {
//       display: block;
//       max-width: 100%;
//       height: 95%;
//       &__img {
//         background: url(./images/serviceFlow.png) no-repeat center / cover;
//         display: block;
//         max-width: 100%;
//         height: inherit;
//         margin: 0 auto;
//       }
//     }
//   }
//   // midle_content 영역
//   .changeIcon-1 {
//     background: url('/images/img22.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-2 {
//     background: url('/images/img24.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;
//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }

//   .changeIcon-3 {
//     background: url('/images/img25.jpg') no-repeat center / cover;
//     opacity: 0.7;
//     padding: 4rem 6rem;

//     .font-medium {
//       color: #fff;
//       font-size: 1rem;
//     }
//   }
//   .changeScaleIcon {
//     transition:
//       transform 0.5s ease,
//       opacity 0.5s ease;
//     &:hover {
//       transform: scale(1.1);
//       opacity: 1;
//     }
//   }

//   .thisMargin {
//     width: 100%;
//   }

//   .intStrongText {
//     font-size: 2rem;
//     margin-bottom: 2rem;
//     font-weight: 400;
//   }

//   // .titleAlignBox {}

//   .titleLeftText {
//     display: block;
//     text-align: left;
//     font-size: 4rem;
//     font-style: italic;
//   }
//   .titleRightText {
//     display: block;
//     text-align: right;
//     font-size: 4rem;
//     font-style: italic;
//     margin-bottom: 2rem;
//   }

//   .companyContainer {
//     background: url(./images/visualBg.jpg) no-repeat center/cover;
//     padding-top: 120px;
//     height: 100%;
//     overflow: hidden;
//     opacity: 0.9;

//     &::before {
//       content: '';
//       display: block;
//       position: absolute;
//       top: 20%;
//       left: 48.5%;
//       transform: translateX(-50%);
//       width: 100px;
//       height: 100px;
//       background: rgba(255, 217, 0, 0.322);
//       box-shadow: 0px 0px 100px gold;
//       border-radius: 100%;
//     }

//     &__inner {
//       max-width: 1600px;
//       margin: 0 auto;
//       padding: 0 2rem;
//       background: rgba(107, 107, 107, 0.219);

//       &__content {
//         position: relative;
//         border: 2px solid #fff;
//         border-radius: 20px;
//         padding: 2rem 0;
//         margin: 2rem 0;

//         &__title {
//           text-align: center;
//           font-weight: 700;
//           font-size: 2.5rem;
//           color: #eee;
//         }
//         &__desc {
//           width: 550px;
//           margin: 2rem auto;
//           padding: 1rem 4rem;
//           text-align: left;
//           font-weight: 700;
//           font-size: 1.5rem;
//           font-style: italic;
//           color: #fff;
//           border-top: 2px solid #fff;
//           border-bottom: 2px solid #fff;

//           span {
//             display: block;
//             margin-top: 10px;
//             text-align: right;
//             font-size: 1.5rem;
//             font-style: italic;
//           }
//         }

//         &__descText {
//           width: 70%;
//           margin: 2rem auto;
//           text-align: center;
//           font-size: 1.125rem;
//           font-weight: 700;
//           word-break: keep-all;
//           color: #fff;
//         }

//         &__textImportant {
//           width: 75%;
//           margin: 2rem auto;

//           p {
//             font-size: 0.925rem;
//             text-align: center;
//             color: #fff;
//             font-weight: 400;

//             &.importantStrong {
//               font-weight: 700;
//               font-size: 1.6rem;
//             }
//           }

//           .importantSpan {
//             display: block;
//             margin: 1rem auto;
//             padding: 10px;
//             width: 70%;
//             font-weight: 700;
//             font-size: 1.175rem;
//             word-break: break-all;
//             background: #fff;
//             color: #111;
//           }
//         }

//         &__importantDesc {
//           margin: 2rem auto;
//           p {
//             width: 55%;
//             padding: 0 1rem;
//             margin: 0 auto;
//             line-height: 2;
//             color: #fff;
//             font-size: 1.15rem;
//             word-break: break-all;

//             .t-bold {
//               color: gold;
//               font-size: 1rem;
//               font-weight: 700;
//             }
//           }
//           .ceoName {
//             width: 70%;
//             margin: 0 auto;
//             padding-top: 2rem;
//             color: #fff;
//             font-size: 1.1rem;
//             font-weight: 600;
//             text-align: right;

//             span {
//               display: block;
//               margin-top: 10px;
//               padding-bottom: 2rem;
//               font-weight: 700;
//               text-align: right;
//             }
//           }
//         }
//       }
//     }
//   }
//   #kakaoLiveContent {
//     text-align: center;
//     margin: 0 auto;
//   }

//   .kakaoLiveContent {
//     p {
//       text-align: center;
//     }
//   }
//   #kakaoTitle {
//     text-align: center;
//     color: #222;
//   }
//   #kakaoLiveBtn {
//     width: 70%;
//     margin-bottom: 10px;
//     padding: 10px;
//     font-weight: 700;
//     background: #f9e000;
//     color: #222;
//   }

//   .galleryPage {
//     padding: 50px;
//     margin: -4rem 0 4rem 0;

//     h1 {
//       margin: 4rem;
//       font-size: 3rem;
//       font-weight: 700;
//       text-align: center;
//       color: #2349a7;
//     }

//     &__gContent {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 10px;

//       &__gImg {
//         position: relative;
//         flex: 1 1 calc(33.333% - 20px); /* 3개씩 나누고 간격 고려한 너비 설정 */
//         height: 260px; /* 높이 예시 */
//         background-color: #ccc; /* 배경 색 예시 */
//         box-sizing: border-box; /* padding, border를 포함한 전체 너비 계산 */
//         overflow: hidden; /* 오버플로우되는 요소 숨기기 */

//         &:nth-child(1) {
//           background: url(./images/changeBg_1.png) no-repeat center/cover;
//         }
//         &:nth-child(2) {
//           background: url(./images/changeBg_2.png) no-repeat center/cover;
//         }
//         &:nth-child(3) {
//           background: url(./images/changeBg_3.png) no-repeat center/cover;
//         }
//         &:nth-child(4) {
//           background: url(./images/changeBg_4.png) no-repeat center/cover;
//         }
//         &:nth-child(5) {
//           background: url(./images/changeBg_5.png) no-repeat center/cover;
//         }
//         &:nth-child(6) {
//           background: url(./images/changeBg_6.png) no-repeat center/cover;
//         }

//         .overlay {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.6);
//           color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           opacity: 0;
//           transition: opacity 0.3s ease-in-out;
//           font-size: 1.2rem;
//           font-weight: 700;
//         }

//         &:hover .overlay {
//           opacity: 1;
//         }
//       }
//     }
//   }

//   // 문의페이지 FAQ 리스트
//   .boardContainer {
//     position: relative;

//     &__box {
//       &__list {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background: #ddd;
//         // border-top: 1px solid #333;
//         border-bottom: 1px solid #333;

//         li {
//           padding: 10px;
//           // border-right: 2px solid #999999;

//           &:first-child {
//             border-left: none;
//           }
//           &:last-child {
//             border-right: none;
//           }

//           a {
//             font-size: 1rem;
//             font-weight: 700;
//             color: #444;
//           }
//         }
//       }
//     }

//     &__faq {
//       dl {
//         background: #eee;
//         dt {
//           color: #333;
//         }
//         dd {
//           .faq-pre {
//             white-space: pre-wrap; /* 줄 바꿈을 허용하여 좌우 스크롤 방지 */
//             word-wrap: break-word; /* 긴 단어가 있을 경우 줄 바꿈 */
//             overflow: hidden; /* 스크롤 방지 */
//             max-height: 400px; /* 필요시 최대 높이 설정 */
//             background: #d8d8d8;
//             color: #363636;
//             font-size: 1rem;
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1600px) {
//   .imgBg {
//     width: 100vw;
//     height: 100vh;
//   }

//   .imgHandle {
//     position: absolute;
//     bottom: 0;
//     left: -7%;
//     width: 900px;
//     height: auto;
//     // z-index: 4;
//   }

//   .colServiceSection {
//     text-align: center;
//     margin: 4rem auto;
//     width: 100vw;
//     height: 100vh;

//     h2 {
//       text-align: center;
//     }
//     &__imgBox {
//       display: block;
//       max-width: 100%;
//       height: 100%;
//       &__img {
//         background: url(/_next/static/media/serviceFlow.0956b841.png) no-repeat
//           center / cover;
//         display: block;
//         max-width: 95%;
//         height: inherit;
//         margin: 0 auto;
//       }
//     }
//   }

//   #contactSection {
//     .container-fluid {
//       .row {
//         align-items: center;
//       }
//     }
//   }

//   #kakaoLiveContent {
//     text-align: center;
//     margin: 0 auto;
//   }

//   .kakaoLiveContent {
//     p {
//       text-align: center;
//     }
//   }
//   #kakaoTitle {
//     text-align: center;
//     color: #222;
//   }
//   #kakaoLiveBtn {
//     width: 40%;
//     margin-bottom: 10px;
//     padding: 10px;
//     font-weight: 700;
//     background: #f9e000;
//     color: #222;
//   }

//   .gallery-img {
//     img {
//       width: 80%;
//       height: 270px;
//     }
//   }

//   // 문의페이지 FAQ 리스트
//   .boardContainer {
//     position: relative;

//     &__box {
//       &__list {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background: #ddd;
//         // border-top: 1px solid #333;
//         border-bottom: 1px solid #333;

//         li {
//           padding: 10px;
//           // border-right: 2px solid #999999;

//           &:first-child {
//             border-left: none;
//           }
//           &:last-child {
//             border-right: none;
//           }

//           a {
//             font-size: 1rem;
//             font-weight: 700;
//             color: #444;
//           }
//         }
//       }
//     }

//     &__faq {
//       dl {
//         background: #eee;
//         dt {
//           color: #333;
//         }
//         dd {
//           .faq-pre {
//             white-space: pre-wrap; /* 줄 바꿈을 허용하여 좌우 스크롤 방지 */
//             word-wrap: break-word; /* 긴 단어가 있을 경우 줄 바꿈 */
//             overflow: hidden; /* 스크롤 방지 */
//             max-height: 400px; /* 필요시 최대 높이 설정 */
//             background: #d8d8d8;
//             color: #363636;
//             font-size: 1rem;
//           }
//         }
//       }
//     }
//   }
// }
